import React from "react";
import { Col } from "antd";

import * as classes from "./Enroll.module.scss";
import { variants } from "../../../utils/variants";
import { CustomButton } from "../../adaptors/CustomButton";
import { HOME_LANDING_PAGE_FILES_PATH } from "../../../utils/localization";

const Enroll = () => {
  return (
    <Col className={classes.container}>
      <Col className={classes.wrapper}>
        <img
          src={`${HOME_LANDING_PAGE_FILES_PATH}/enroll-now-hero-min.webp`}
          loading="lazy"
        />
        <Col>
          <Col>
            <h1>80% of all our program </h1>
            <h1>graduates get a paid</h1>
            <h1>internship or job.</h1>
          </Col>
          <Col>
            <CustomButton
              title={"Enroll Now"}
              variant={variants.primaryButton}
              customClass={classes.buttonCustomClass}
              customTextClass={classes.buttonCustomTextClass}
              onClick={() => {
                window.open("/internship-program");
              }}
            />
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

export default Enroll;
