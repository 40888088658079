import { HOME_LANDING_PAGE_FILES_PATH } from "../../../utils/localization";

export const brands = [
  {
    id: 1,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/leadSquare.svg`,
  },
  {
    id: 2,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/linkedIn.svg`,
  },
  {
    id: 3,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/combinator.svg`,
  },
  {
    id: 4,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/near.svg`,
  },
  {
    id: 5,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/village-capital.svg`,
  },
  {
    id: 6,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/mailmado.svg`,
  },
  {
    id: 7,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/aws-edStart.svg`,
  },
  {
    id: 8,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/lpi.svg`,
  },
  {
    id: 9,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/pwc.svg`,
  },
  {
    id: 12,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/global-shapers-logo.svg`,
  },
];

export const categories = {
  all_categories: {
    title: "All Categories",
    value: "All Categories",
    slug: "all-categories",
    id: 1,
  },
  design: {
    title: "Design",
    value: "Design",
    slug: "design",
    id: 2,
  },
  marketing: {
    title: "Marketing",
    value: "Marketing",
    slug: "marketing",
    id: 3,
  },
  "coding-and-development": {
    title: "Coding",
    value: "Coding",
    slug: "coding-and-development",
    id: 4,
  },
  "business-and-entrepreneurship": {
    title: "Business",
    value: "Business",
    slug: "business-and-entrepreneurship",
    id: 5,
  },
  "software-and-technology": {
    title: "Software",
    value: "Software",
    slug: "software-and-technology",
    id: 6,
  },
  vacational: {
    title: "Vocational",
    value: "Vocational",
    slug: "vocational",
    id: 7,
  },
  "finance-and-accounting": {
    title: "Finance",
    value: "Finance",
    slug: "finance-and-accounting",
    id: 8,
  },
};

export const reviewers = [
  {
    id: 1,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/reviewer-image-1.png`,
    companyLogo: `${HOME_LANDING_PAGE_FILES_PATH}/incraSooft.svg`,
  },
  {
    id: 2,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/reviewer-image-2.png`,
    companyLogo: `${HOME_LANDING_PAGE_FILES_PATH}/hawks.svg`,
  },
  {
    id: 3,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/reviewer-image-3.png`,
    companyLogo: `${HOME_LANDING_PAGE_FILES_PATH}/risp.svg`,
  },
  {
    id: 4,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/reviewer-image-4.png`,
    companyLogo: `${HOME_LANDING_PAGE_FILES_PATH}/risp.svg`,
  },
  {
    id: 5,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/reviewer-image-5.png`,
    companyLogo: `${HOME_LANDING_PAGE_FILES_PATH}/kingFisher.svg`,
  },
  {
    id: 6,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/reviewer-image-6.png`,
    companyLogo: `${HOME_LANDING_PAGE_FILES_PATH}/styledo.svg`,
  },
];

export const reviewes = [
  {
    id: 1,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/reviewer-image-1-min.jpg`,
    companyLogo: `${HOME_LANDING_PAGE_FILES_PATH}/linkedInIcon.svg`,
    name: "Dulam Adarsh",
    review:
      "I am from an engineering background and only because of Unschool’s beautifully created Digital Marketing course, now I am placed in IncraSoft as a Digital marketing Intern. The minor and major project experience and the Unschool certificate helped me land this internship, I am grateful for the opportunity! Thank you Unschool!",
  },
  {
    id: 2,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/reviewer-image-2.png`,
    companyLogo: `${HOME_LANDING_PAGE_FILES_PATH}/linkedInIcon.svg`,
    name: "Pranay Bathija",
    review:
      "I am a business student and I came across Digital Marketing course from Unschool.I simply loved the detailed course and after completing it I got placed in Cambrionics Life Sciences as Digital Marketeer handling their Social media.",
  },
  {
    id: 3,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/reviewer-image-5.png`,
    companyLogo: `${HOME_LANDING_PAGE_FILES_PATH}/linkedInIcon.svg`,
    name: "Shalom Varghese",
    review:
      "My experience as sales and marketing team executive with Unschool and completing soft skills course was amazing! I was Unschool Helped me kick starting my career! I got placed as social media manager for Cambrionics Life Sciences.",
  },
  {
    id: 4,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/reviewer-image-6.png`,
    companyLogo: `${HOME_LANDING_PAGE_FILES_PATH}/linkedInIcon.svg`,
    name: "Usharani Jayakrishnan",
    review:
      "At the age of 42 i wanted to do a career switch from commerce to data science with the market it holds. I pursued my Data certification from Unschool, and ihave landed an internship with PPO as a data analyst with a stipend of 20k per month.",
  },
  {
    id: 5,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/reviewer-image-7.png`,
    companyLogo: `${HOME_LANDING_PAGE_FILES_PATH}/linkedInIcon.svg`,
    name: "Shinny P",
    review:
      "I did my digital marketing course from the comfort of my home with Unschool and that's a wise decison I have taken for my career.",
  },
  {
    id: 6,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/reviewer-image-8.png`,
    companyLogo: `${HOME_LANDING_PAGE_FILES_PATH}/linkedInIcon.svg`,
    name: "Jyoti Meena",
    review:
      "My journey with Unschool was astounding. Here's a great opportunity to learn with internships to practice what you learnt.Thank you for patiently listening to us and taking our every doubt and thought seriously.",
  },
];

export const achievements = [
  {
    id: 1,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/nsdc-logo.png`,
    content: "Unschool is now an Approved Training Partner of NSDC",
    href: "https://nsdcindia.org",
  },
  {
    id: 2,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/yourstory.png`,
    content:
      "This Hyderabad-based edtech startup aims to help students ‘un’school themselves.",
    href: "https://yourstory.com/2020/09/hyderbad-edtech-startup-students-unschool-learning-management",
  },
  {
    id: 3,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/money-control (2).png`,
    content: "Take a look at the top 10 Indian startups.",
    href: "https://www.moneycontrol.com/news/photos/business/in-pics-take-a-look-at-the-top-10-indian-startups-5870781-2.html",
  },
  {
    id: 5,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/print.png`,
    content: "Preparing India for the future workplace.",
    href: "https://theprint.in/ani-press-releases/preparing-india-for-the-future-workplace/1030863/",
  },
  {
    id: 6,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/tech-crunch.png`,
    content: "Y Combinator widens its bet in edtech in latest batch.",
    href: "https://techcrunch.com/2021/03/23/y-combinator-widens-its-bet-in-edtech-in-latest-batch/",
  },
  {
    id: 6,
    image: `${HOME_LANDING_PAGE_FILES_PATH}/bussiness-world.png`,
    content: "Hyderabad Youths Create Start-Up- 'Unschool, To Bridge...",
    href: "https://www.businessworld.in/article/Hyderabad-youths-create-start-up-Unschool-to-bridge-gap-between-rates-of-graduation-and-unemployment/15-09-2020-320845/",
  },
];

export const categoriesFooter = {
  design: {
    title: "Design",
    value: "Design",
    slug: "design",
    id: 2,
  },
  marketing: {
    title: "Marketing",
    value: "Marketing",
    slug: "marketing",
    id: 3,
  },
  "coding-and-development": {
    title: "Coding",
    value: "Coding",
    slug: "coding-and-development",
    id: 4,
  },
  "business-and-entrepreneurship": {
    title: "Business",
    value: "Business",
    slug: "business-and-entrepreneurship",
    id: 5,
  },
  "software-and-technology": {
    title: "Software",
    value: "Software",
    slug: "software-and-technology",
    id: 6,
  },
  vacational: {
    title: "Vocational",
    value: "Vocational",
    slug: "vocational",
    id: 7,
  },
  "finance-and-accounting": {
    title: "Finance",
    value: "Finance",
    slug: "finance-and-accounting",
    id: 8,
  },
};

export const coaches = [
  {
    name: "Manikumar Perla",
    course: "Full Stack Development",
    imageUrl: `${HOME_LANDING_PAGE_FILES_PATH}/Manikumar.jpg`,
    companyLogo: `${HOME_LANDING_PAGE_FILES_PATH}/amazon.png`,
    id: 1,
    linkedInUrl: "https://www.linkedin.com/in/manikumar-perla/",
  },
  {
    name: "Harish Balaji",
    course: "Advanced Python",
    imageUrl: `${HOME_LANDING_PAGE_FILES_PATH}/harish-min.jpeg`,
    companyLogo: `${HOME_LANDING_PAGE_FILES_PATH}/morgan-stanley.png`,
    id: 2,
  },
  {
    name: "Himanshu Raghani",
    course: "Stock Market & Personal Finance",
    imageUrl: `${HOME_LANDING_PAGE_FILES_PATH}/himanshu-min.jpeg`,
    companyLogo: `${HOME_LANDING_PAGE_FILES_PATH}/kotak-securities-logo.png`,
    id: 3,
    linkedInUrl: "https://www.linkedin.com/in/himanshu-raghani-9bab37b7/",
  },
  {
    name: "Nikanksha Jain",
    course: "Advanced Data Science Certification",
    imageUrl: `${HOME_LANDING_PAGE_FILES_PATH}/Nikanksha.jpg`,
    companyLogo: `${HOME_LANDING_PAGE_FILES_PATH}/hcl.png`,
    id: 4,
  },
  {
    name: "Abhishek Tatineni",
    course: "Advanced Microsoft Excel Certification",
    imageUrl: `${HOME_LANDING_PAGE_FILES_PATH}/abhishek-min.jpeg`,
    companyLogo:
      "http://www.logotaglines.com/wp-content/uploads/2016/11/morgan-stanley-logo.jpg",
    id: 5,
    companyLogo: `${HOME_LANDING_PAGE_FILES_PATH}/aiesec.png`,
    linkedInUrl: "https://www.linkedin.com/in/abhishek-tatineni/",
  },
  {
    name: "Anisha Sharma",
    course: "Android App Development using Java",
    imageUrl: `${HOME_LANDING_PAGE_FILES_PATH}/anisha-min.jpeg`,
    companyLogo: `${HOME_LANDING_PAGE_FILES_PATH}/swiss-himmel.png`,
    id: 6,
    linkedInUrl: "https://www.linkedin.com/in/anisha-sharma-43b549161/",
  },
  {
    name: "Tushar Dhyani",
    course: "Advanced Artificial Intelligence Certification",
    imageUrl: `${HOME_LANDING_PAGE_FILES_PATH}/Tushar-min.jpg`,
    companyLogo: `${HOME_LANDING_PAGE_FILES_PATH}/sony.png`,
    id: 7,
    linkedInUrl: "https://www.linkedin.com/in/tushar-dhyani/",
  },
  {
    name: "Dr. Bart Jaworski",
    course: "Product Management",
    imageUrl: `${HOME_LANDING_PAGE_FILES_PATH}/bart-min.jpg`,
    companyLogo: `${HOME_LANDING_PAGE_FILES_PATH}/microsoft.png`,
    id: 8,
    linkedInUrl: "https://www.linkedin.com/in/bart-jaworski/",
  },
  {
    name: "Tanmoy Kumar Das",
    course: "Google Ads",
    imageUrl: `${HOME_LANDING_PAGE_FILES_PATH}/tanmoy-min.jpg`,
    companyLogo: `${HOME_LANDING_PAGE_FILES_PATH}/regali.png`,
    id: 9,
    linkedInUrl: "https://www.linkedin.com/in/tanmoykumardas/",
  },
  {
    name: "Pooja Pathak",
    course: "Public Relations",
    imageUrl: `${HOME_LANDING_PAGE_FILES_PATH}/Pooja.jpeg`,
    companyLogo: `${HOME_LANDING_PAGE_FILES_PATH}/media-mantra.png`,
    id: 10,
    linkedInUrl: "https://www.linkedin.com/in/pooja-pathak-b864aa110/",
  },
  {
    name: "Akahsy Salaria",
    course: "Advanced Digital Analytics Certification",
    imageUrl: `${HOME_LANDING_PAGE_FILES_PATH}/akshay-min.jpg`,
    companyLogo: `${HOME_LANDING_PAGE_FILES_PATH}/tata-min.webp`,
    id: 11,
    linkedInUrl: "https://www.linkedin.com/in/akshay-salaria/",
  },
];

export const stats = [
  { value: "200k+", title: "Learners", id: 1 },
  { value: "500K+", title: "Enrollments", id: 2 },
  { value: "100%", title: "Placements", id: 3 },
  { value: "150+", title: "Industry experts", id: 4 },
  { value: "110+", title: "Hiring Partners", id: 5 },
];

export const faqs = [
  {
    question: "Unsure about which course to choose?",
    answer:
      "Contact our career counsellor at +91 9154254541, and they will assist you in understanding your interest and career potential. Otherwise, Sign-up with our Kickstarter program and explore what interests you.",
  },
  {
    question: "Is a Demo video available for any course",
    answer:
      "Yes, as you sign-up, you will be given access to watch one whole chapter for any course as a free-demo video.",
  },
  {
    question: "Who are these industry experts who teach the course exactly?",
    answer:
      "Our coaches have hands-on experience in the domain or subject they specialise in and are young achievers working with renowned institutions.",
  },
  {
    question: "What are the modes of payment available?",
    answer:
      "You can make payments through a credit or debit card, or we provide other payment gateways and EMI options as per your eligibility.",
  },
  {
    question: "Why should I learn at Unschool?",
    answer:
      "We are India's first streaming platform for education providing access to 150+ courses with a 7-day free trial. Not just that, while you choose to upskill, we help with career assistance with our Level-up and Job program",
  },
  {
    question: "How can I get the NSDC certificate?",
    answer:
      "We have over 30 courses/programs that are NSDC approved. You will receive the NSDC certificate via email once you finish the course/programs successfully. This is only valid if you have pre-purchased the NSDC add-on while buying the course/program.",
  },
  {
    question: "How long is the course duration?",
    answer:
      "Course duration varies based on our programs. From self-paced learning to live lectures and one to one mentorship, we have got you covered.",
  },
];
