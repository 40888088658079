import React from "react";

import LayoutWithOutPadding from "../components/LayoutWithOutPadding";
import Hero from "../components/InternshipProgramLanding1.0/Hero";
import Poweredby from "../components/HomeLandingPage1.0/Poweredby";
import Enroll from "../components/HomeLandingPage1.0/Enroll";
import Explore from "../components/HomeLandingPage1.0/Explore";
import LearningRoadMap from "../components/HomeLandingPage1.0/LearningRoadMap";
import Coaches from "../components/HomeLandingPage1.0/Coaches";
import Stats from "../components/HomeLandingPage1.0/Stats";
import Courses from "../components/HomeLandingPage1.0/Courses";
import Reviews from "../components/HomeLandingPage1.0/Reviews";
import Achievements from "../components/HomeLandingPage1.0/Achievements";
import Faq from "../components/HomeLandingPage1.0/Faq";
import homePageSeoetails from "../constants/homePageSeoDetails";
import FooterCourseCategories from "../components/HomeLandingPage1.0/FooterCourseCategories";
import Seo from "../components/HomeLandingPage1.0/Seo";
import { useCategoryCourseList } from "../components/HomeLandingPage1.0/hooks/useCategoryCourseList";
import NSDC from "../components/HomeLandingPage1.0/NSDC";

const Home = () => {
  const { categoryCourseList } = useCategoryCourseList();

  return (
    <LayoutWithOutPadding>
      <Hero />
      <Poweredby />
      <Enroll />
      <Explore />
      <LearningRoadMap />
      <Coaches />
      <Stats />
      <Courses categoryCourseList={categoryCourseList} />
      <NSDC />
      <Reviews />
      <Achievements />
      <Faq />
      <FooterCourseCategories categoryCourseList={categoryCourseList} />
    </LayoutWithOutPadding>
  );
};

export function Head() {
  const seoTags = homePageSeoetails;

  return (
    <Seo
      title={seoTags.metaTags.titleMetaTagContent}
      description={seoTags?.metaTags?.descriptionMetaTagContent}
      keywords={seoTags?.metaTags?.keywordsMetaTagContent}
      robots={seoTags?.metaTags?.robotsMetaTagContent}
      httpEquiv={seoTags?.metaTags?.httpEquivMetaTagContent}
      language={seoTags?.metaTags?.languageMetaTagContent}
      revisitAfter={seoTags?.metaTags?.revisitAfterMetaTagContent}
      author={seoTags?.metaTags?.authorMetaTagContent}
      schemaMarkup={seoTags?.scriptTags}
    />
  );
}

export default Home;
