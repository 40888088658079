import React from "react";
import { Col } from "antd";

import * as classes from "./Explore.module.scss";
import yellowTickIcon from "../images/yellow-tick-icon.svg";
import exploreHeroImage from "../images/explore-hero-1.png";
import { CustomButton } from "../../adaptors/CustomButton";
import { variants } from "../../../utils/variants";
import { HOME_LANDING_PAGE_FILES_PATH } from "../../../utils/localization";

const InternshipProgram = () => {
  return (
    <>
      <Col className={classes.productLineContent}>
        <Col className={classes.left}>
          <h1 className={classes.headingDesktop}>
            Upskill and get a <br /> <span>guaranteed</span> internship.
          </h1>
          <h1 className={classes.headingTablet}>
            Upskill and get a <span>guaranteed</span> internship.
          </h1>
          <Col className={classes.features}>
            <Col>
              <img src={yellowTickIcon} />
              Industry Approved Self-Paced Content
            </Col>
            <Col>
              <img src={yellowTickIcon} />
              Realtime Projects & Case Studies
            </Col>
            <Col>
              <img src={yellowTickIcon} />
              NSDC Certificate & Internship Opportunities
            </Col>
          </Col>
          <CustomButton
            title={"Explore Programs"}
            variant={variants.primaryButton}
            customClass={classes.buttonCustomClass}
            customTextClass={classes.buttonCustomTextClass}
            onClick={() => {
              window.open("/internship-program");
            }}
          />
        </Col>
        <Col className={classes.buttonWrapper}>
          <CustomButton
            title={"Get Internship"}
            variant={variants.primaryButton}
            customClass={classes.buttonCustomClass}
            customTextClass={classes.buttonCustomTextClass}
          />
        </Col>
        <Col className={classes.right}>
          <img
            src={`${HOME_LANDING_PAGE_FILES_PATH}/internship-explore.webp`}
            loading="lazy"
          />
        </Col>
      </Col>
    </>
  );
};

export default InternshipProgram;
